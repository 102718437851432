/* eslint-disable */

<template>
  <div id="custom264345530216218624">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <div class="bg-dark main-border rounded-lg mb-base" :style="{ display: currentPage === 0 ? 'block' : 'none' }">
            <div class="flex flex-col items-between p-4 main-bottom-border">
              <h4 class="self-center font-light" style="font-size: 16px">
                <b>High (CVSS: 8.2)</b><br /><br /><b>NVT:</b> The IPSEC configuration uses a weak key agreement protocol<br /><br />The
                IPSEC configuration uses a weak key agreement
              </h4>
              <div class="flex w-full mt-3 gap-x-3">
                <vue-draggable-container id="wb1" class="wbForensics w-1/2">
                  <h5 class="dragBlock cursor-pointer w-full" style="min-width: 4rem !important">1</h5>
                </vue-draggable-container>
                <vue-draggable-container id="wb1-2" class="wbForensics w-1/2">
                  <h5 class="dragBlock cursor-pointer w-full" style="min-width: 4rem !important">1</h5>
                </vue-draggable-container>
              </div>
            </div>
            <div class="flex flex-col items-between p-4 main-bottom-border">
              <h4 class="self-center font-light" style="font-size: 16px">
                <b>High (CVSS: 8.2)</b><br /><br /><b>NVT:</b> Open AXFR permitted<br /><br />The server permits AXFR from anonymous
                sources.
              </h4>
              <vue-draggable-container id="wb2" class="wbForensics mt-3" style="width: 9rem">
                <h5 class="dragBlock cursor-pointer w-full" style="min-width: 4rem !important">2</h5>
              </vue-draggable-container>
            </div>
            <div class="flex flex-col items-between p-4 main-bottom-border">
              <h4 class="self-center font-light" style="font-size: 16px">
                <b>High (CVSS: 8.0)</b><br /><br /><b>NVT:</b> SSL/TLS: Electronic Codebook Mode of Operation in use<br /><br />Reports
                where attack vectors exist only on HTTPS services.
              </h4>
              <vue-draggable-container id="wb3" class="wbForensics mt-3" style="width: 9rem">
                <h5 class="dragBlock cursor-pointer w-full" style="min-width: 4rem !important">3</h5>
              </vue-draggable-container>
            </div>
          </div>

          <vx-card class="" title="Page Switcher">
            <vs-button
              color="success"
              type="filled"
              icon-pack="feather"
              icon="icon-arrow-right-circle"
              style="width: 100%; z-index: 1"
              class="font-bold flex"
              button
              size="large"
              @click="next_page"
              v-if="currentPage === 0"
              >Next Page
            </vs-button>
            <vs-button
              color="danger"
              type="filled"
              icon-pack="feather"
              icon="icon-arrow-left-circle"
              style="width: 100%"
              class="font-bold"
              button
              size="large"
              @click="previous_page"
              v-else
              >Previous Page
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div :style="{ display: currentPage === 0 ? '' : 'none!important' }">
              <div class="vx-row">
                <div class="vx-col w-4/5 p-5 pt-2 rounded">
                  <div class="relative mb-3 main-border rounded-lg border-4 bg-light border-solid p-3 z-10">
                    <h2 class="font-bold p-2 rounded bg-success mb-4">Site A</h2>
                    <div class="grid grid-cols-3 gap-3">
                      <div v-for="item in siteA" class="flex flex-col justify-between items-center main-border bg-dark rounded-lg p-4">
                        <p class="flex text-center font-semibold mb-2">{{ item.name }}</p>
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerForensics w-full" :id="item.id" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-col w-1/5">
                  <div class="flex relative justify-center items-center align-middle h-full p-5">
                    <div class="rounded-full bg-light main-border z-10">
                      <lottie-animation
                        path="https://connect-cdn.intellectualpoint.com/assets/lotties/internet-rotate.json"
                        :loop="true"
                        :autoPlay="true"
                        :speed="1"
                        :width="150"
                      />
                    </div>
                    <div class="forensics-line-left"></div>
                    <div class="forensics-line-up"></div>
                  </div>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col relative w-1/5">
                  <div class="forensics-line-rotate"></div>
                </div>

                <div class="vx-col w-4/5 p-5 rounded">
                  <div class="relative mb-3 main-border rounded-lg border-4 bg-light border-solid p-3 z-10">
                    <h2 class="font-bold p-2 rounded bg-primary mb-4">Site B</h2>
                    <div class="grid grid-cols-3 gap-4">
                      <div v-for="item in siteB" class="flex flex-col justify-between items-center main-border bg-dark rounded-lg p-4">
                        <p class="flex text-center font-semibold mb-2">{{ item.name }}</p>
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerForensics w-full" :id="item.id" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-cols-2 gap-4" :style="{ display: currentPage === 1 ? 'grid' : 'none!important' }">
              <div class="bg-light rounded-lg main-border">
                <div>
                  <div class="flex p-5 rounded-t-lg main-bottom-border">
                    <h3 class="font-bold self-center">High (CVSS: 8.2) NVT: The IPSEC</h3>
                  </div>
                  <div class="p-5">
                    <p class="text-lg"><b>NVT:</b> The IPSEC configuration uses a weak key agreement protocol</p>
                    <h2 class="mt-3">Summary</h2>
                    <h5 class="mt-2 text-grey font-light">The IPSEC configuration uses a weak key agreement protocol.</h5>
                    <h2 class="mt-4">Role</h2>
                    <v-select v-model="page2.IPSEC.value" :options="page2Options" class="w-full mt-1" placeholder="Select from Dropdown" />
                    <small v-if="page2.IPSEC.marked_score === false" class="ml-1 text-danger font-semibold">This answer is incorrect</small>
                    <small v-if="page2.IPSEC.marked_score === true" class="ml-1 text-success font-semibold">This answer is correct</small>
                  </div>
                </div>
              </div>

              <div class="bg-light rounded-lg main-border">
                <div>
                  <div class="flex p-5 rounded-t-lg main-bottom-border">
                    <h3 class="font-bold self-center">High (CVSS: 8.2) NVT: The IPSEC</h3>
                  </div>
                  <div class="p-5">
                    <p class="text-lg"><b>NVT:</b> The IPSEC configuration uses a weak key agreement protocol</p>
                    <h2 class="mt-3">Summary</h2>
                    <h5 class="mt-2 text-grey font-light">The IPSEC configuration uses a weak key agreement protocol.</h5>
                    <h2 class="mt-4">Role</h2>
                    <v-select v-model="page2.IPSEC2.value" :options="page2Options" class="w-full mt-1" placeholder="Select from Dropdown" />
                    <small v-if="page2.IPSEC2.marked_score === false" class="ml-1 text-danger font-semibold"
                      >This answer is incorrect</small
                    >
                    <small v-if="page2.IPSEC2.marked_score === true" class="ml-1 text-success font-semibold">This answer is correct</small>
                  </div>
                </div>
              </div>

              <div class="bg-light rounded-lg main-border">
                <div>
                  <div class="flex p-5 rounded-t-lg main-bottom-border">
                    <h3 class="font-bold self-center">High (CVSS: 8.2) NVT: Open AXFR Permitted</h3>
                  </div>
                  <div class="p-5">
                    <p class="text-lg"><b>NVT:</b> Open AXFR permitted</p>
                    <h2 class="mt-3">Summary</h2>
                    <h5 class="mt-2 text-grey font-light">The server permits AXFR from anonymous sources.</h5>
                    <h2 class="mt-4">Role</h2>
                    <v-select v-model="page2.CVSS82.value" :options="page2Options" class="w-full mt-1" placeholder="Select from Dropdown" />
                    <small v-if="page2.CVSS82.marked_score === false" class="ml-1 text-danger font-semibold"
                      >This answer is incorrect</small
                    >
                    <small v-if="page2.CVSS82.marked_score === true" class="ml-1 text-success font-semibold">This answer is correct</small>
                  </div>
                </div>
              </div>

              <div class="bg-light rounded-lg main-border">
                <div>
                  <div class="flex p-5 rounded-t-lg main-bottom-border">
                    <h3 class="font-bold self-center">High (CVSS: 8.0) NVT: SSL/TLS: Electronic</h3>
                  </div>
                  <div class="p-5">
                    <p class="text-lg"><b>NVT:</b> SSL/TLS: Electronic Codebook Mode of Operation in use</p>
                    <h2 class="mt-3">Summary</h2>
                    <h5 class="mt-2 text-grey font-light">Reports where attack vectors exist only on HTTPS services.</h5>
                    <h2 class="mt-4">Role</h2>
                    <v-select v-model="page2.CVSS80.value" :options="page2Options" class="w-full mt-1" placeholder="Select from Dropdown" />
                    <small v-if="page2.CVSS80.marked_score === false" class="ml-1 text-danger font-semibold"
                      >This answer is incorrect</small
                    >
                    <small v-if="page2.CVSS80.marked_score === true" class="ml-1 text-success font-semibold">This answer is correct</small>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import LottieAnimation from '../../../../../../../../components/lottie/LottieAnimation';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      currentPage: 0,

      page2Options: [
        'Upgrade cipher suites',
        'Implement an ACL in BIND configuration',
        'Implement a honeypot',
        'Download and install the latest firmware',
        'Configure and SSH daemon',
        'Segment ICS and SCADA equipment',
        'Reissue a PKI certificate from a trusted CA',
      ],

      page2: {
        IPSEC: {
          answer: 'Upgrade cipher suites',
          value: '',
          marked_score: null,
        },
        IPSEC2: {
          answer: 'Upgrade cipher suites',
          value: '',
          marked_score: null,
        },
        CVSS82: {
          answer: 'Implement an ACL in BIND configuration',
          value: '',
          marked_score: null,
        },
        CVSS80: {
          answer: 'Reissue a PKI certificate from a trusted CA',
          value: '',
          marked_score: null,
        },
      },

      siteA: [
        {
          name: 'DNS',
          id: 'aInputDNS',
        },
        {
          name: 'HVAC',
          id: 'aInputHVAC',
        },
        {
          name: 'PLC',
          id: 'aInputPLC',
        },
        {
          name: 'Pumps',
          id: 'aInputPumps',
        },
        {
          name: 'Application Server 01',
          id: 'aInputAs01',
        },
        {
          name: 'SCADA Master Controller',
          id: 'aInputSCADA',
        },
        {
          name: 'Directory Server',
          id: 'aInputDs',
        },
        {
          name: 'Web Server 01',
          id: 'aInputWs01',
        },
        {
          name: 'Application Server 02',
          id: 'aInputAs02',
        },
        {
          name: 'VPN Concentrator',
          id: 'aInputVpnC',
        },
      ],

      siteB: [
        {
          name: 'VPN Concentrator',
          id: 'bInputVpnC',
        },
        {
          name: 'Application Server 03',
          id: 'bInputAs03',
        },
        {
          name: 'Application Server 04',
          id: 'bInputAs04',
        },
        {
          name: 'File Server',
          id: 'bInputFs',
        },
        {
          name: 'PLC',
          id: 'bInputPLC',
        },
        {
          name: 'Pumps',
          id: 'bInputPumps',
        },
      ],
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const origSourceContainer = x.data.dragEvent.data.originalSource;
      if (overContainer.classList.contains('wbForensics')) {
        if (overContainer.children.length > 0) {
          return x.cancel();
        }
      }
      if (overContainer && overContainer.classList.contains('dropzone')) {
        const id = origSourceContainer.id;
        document.getElementById(`${id}-cloned`).style.display = 'inline-flex';
        document.getElementById(id).classList.remove('dragBlock');
        document.getElementById(id).classList.add('stillBlockForensicsClassification');
        return;
      }
      document.getElementById(`${origSourceContainer.id}-cloned`).outerHTML = '';
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('aInputDNS').textContent === '2') {
        document.getElementById('aInputDNS').style.borderColor = '#28C76F';
        document.getElementById('aInputDNS').style.backgroundColor = '#28C76F';
        totalScore++;
      }

      if (document.getElementById('aInputVpnC').textContent === '1') {
        document.getElementById('aInputVpnC').style.borderColor = '#28C76F';
        document.getElementById('aInputVpnC').style.backgroundColor = '#28C76F';
        totalScore++;
      }

      if (document.getElementById('aInputWs01').textContent === '3') {
        document.getElementById('aInputWs01').style.borderColor = '#28C76F';
        document.getElementById('aInputWs01').style.backgroundColor = '#28C76F';
        totalScore++;
      }

      if (document.getElementById('bInputVpnC').textContent === '1') {
        document.getElementById('bInputVpnC').style.borderColor = '#28C76F';
        document.getElementById('bInputVpnC').style.backgroundColor = '#28C76F';
        totalScore++;
      }

      for (const [key, value] of Object.entries(this.page2)) {
        if (value.value === value.answer) {
          this.page2[key].marked_score = true;
          totalScore++;
        } else {
          this.page2[key].marked_score = false;
        }
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    previous_page() {
      this.currentPage = 0;
    },
    next_page() {
      this.currentPage = 1;
    },
    /* click_remove (x) {
      const target = x.target
      if (target.classList.contains('stillBlockForensicsClassification')) {
        target.outerHTML = ''
      }
    } */
  },
  components: {
    draggable,
    Prism,
    vSelect,
    LottieAnimation,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.stillBlockForensicsClassification {
  padding: 12px;
  word-break: break-all;
  background-color: rgba(var(--vs-primary), 1);
  display: inline-flex;
  margin: 5px;
  border-radius: 5px;
  font-size: 1.4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 999999999999 !important;
  border: 0.18rem solid transparent;
  font-weight: 800 !important;
  cursor: url('~@/assets/images/cursor/trash-cursor.png'), auto !important;
  background-color: #ffd900;
  color: #000000 !important;
}

#custom264345530216218624 {
  .forensics-line-up {
    position: absolute;
    left: auto;
    right: auto;
    width: 6px;
    height: 380px;
    bottom: -2rem;
    z-index: 0;
    background-color: #0053ff;
  }
  .forensics-line-left {
    position: absolute;
    width: 6vw;
    height: 6px;
    margin-right: 6vw;
    z-index: 0;
    background-color: #0053ff;
  }
  .forensics-line-rotate {
    position: absolute;
    width: 11.5vw;
    height: 6px;
    z-index: 0;
    background-color: #0053ff;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .wbForensics {
    min-height: 55px;
    border-radius: 0.6rem;
    padding: 0.4rem;
    justify-content: center;
    background-color: #040915;
    border: 1px solid #181d2a;
    display: flex;
  }
  .dataForensicsDragBlock {
    min-width: 50px !important;
    background-color: #ffd900;
    color: #000000 !important;
    font-weight: 800 !important;
  }

  .dataForensicsBlock {
    border-color: #ffffff;
    border-style: solid;
    border-width: 2px 0 0 0;
    padding: 7px 0 7px 0;
  }

  .answerForensics {
    min-height: 47.5px;
  }
}
</style>
